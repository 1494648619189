import React from "react";
import Header from "../components/Header";
import SmallLink from "../components/SmallLink";
import BigLink from "../components/BigLink";

class Home extends React.Component {
  // Page d'accueil du portfolio
  render() {
    return (
      <>
      <Header styleHeader="/galery/webp/header-bg.webp" h1="Perrin Le Louarn" h2="Ingénieur Logiciel" />
      <div className="pt-3 row d-flex justify-content-around">
        <div className="col-lg-8 pb-sm-3 pb-md-0 ps-md-5 ps-sm-0 col-md-8 col-sm-12 d-flex flex-column">
          <BigLink href="/skills" icon="/galery/webp/myProjects.webp" text="Compétences & Réalisations" description="Habité par une envie profonde de toujours apprendre, chacun de mes projets me permet de renforcer ma polyvalence. Mon objectif est de concevoir des applications Web qui soient toujours plus efficaces, performantes et agréables." info="page"/>
          <SmallLink href="/cv.pdf" text="CV" info=".PDF"/> 
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 pe-md-5 pe-md-0 d-flex flex-column">
          <SmallLink href="/about" icon="/galery/webp/about.webp" text="à propos" info="Page"/>
          <SmallLink href="mailto:lelouarnperrin@gmail.com"text="Contact" info="Mail"/>
          <SmallLink href="https://github.com/PerrinLigh" text="Github" info="Link"/>
          <SmallLink href="https://www.linkedin.com/in/perrin-le-louarn/" text="LinkedIn" info="Link"/>
        </div>
      </div>
      </>
    );
  }
}

export default Home;
