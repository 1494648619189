import React from 'react';
import "../css/SmallLink.css";

export default function SmallLink({ href, icon, text, info }) {
  const style = {
    backgroundImage: `url(${icon})`,
  };

  return (
    <a href={href} className={icon ? 'small-link pb-3' : 'small-link-alt pb-3'}>
        <header className='d-flex justify-content-between align-items-center'>{text} 
          {info &&<span className='text-end me-1 pe-2 ps-2 badge-info'>{info}</span>}
        </header>
        {icon && <span style={style} className="span-image-smallLink"></span>}
    </a>
  );
}
