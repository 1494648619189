import React from 'react';

export default class NavButtons extends React.Component {
  render() {
    return (
      <div className="nav-buttons">
        <a href="/" aria-label='Retourner au menu principal' className='p-0'id="btn-backtrack"><img src='/galery/webp/logo1.webp' alt="Logo de Perrin Le Louarn - Navigation"/></a>
      </div>
    )
  }
}
