import React from "react"
let year = new Date().getFullYear()

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="d-flex w-100 align-items-center justify-content-center" id="portfolio-footer">
        <p>© Perrin Le Louarn {year}</p>
      </footer>
    )
  }
}
