import React from 'react';
import '../css/Skill.css';

export default function Skill({ title, listCompetences, images, description, info }) {
  const hasImages = images && images.length > 0;

  return (
    <div className="skill pb-2">
      <div className="skill-header">  
        <span>{title}</span>
        {info && <span>{info}</span>}
      </div>
      <div className="row">
        <div className={`col-md-${hasImages ? '4' : '6'} col-sm-12`}>
          <ul className={`competences-list ${hasImages ? '' : 'two-columns'}`}>
            {listCompetences.map((competence, index) => (
              <li key={index}>{competence}</li>
            ))}
          </ul>
        </div>
        {hasImages && (
          <div className="col-md-8 col-sm-12 images">
            {images.map((image, index) => (
              <div key={index} className="image-container">
                <img src={image} alt={`Description of ${title}`} />
                <p>{description}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
