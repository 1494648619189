import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Footer from './components/Footer';
import NavButtons from './components/NavButtons';
import About from './pages/About';
import Skills from './pages/Skills';

function App() {
  return (
    <>
    <NavButtons/>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="about" element={<About/>} />
        <Route path="skills" element={<Skills/>} />
      </Routes>
    </BrowserRouter>
    <Footer />
    </>
  );
}

export default App;
