import React from 'react';
import '../css/Header.css';

const Header = ({ styleHeader, h1, h2 }) => {
  return (
    <header className='pb-3' style={{ backgroundImage: `url(${styleHeader})` }}>
      <div className="header-part d-flex align-items-center justify-content-center p-5">
        <div className="d-flex flex-column header-text">
          <h1>{h1}</h1>
          <h2>{h2}</h2>
        </div>
      </div>  
    </header>
  );
};
export default Header;
