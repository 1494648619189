import React from 'react';
import Header from '../components/Header';
import Skill from '../components/Skill';
import SkillTitle from '../components/SkillTitle';

export default class Skills extends React.Component {
  render() {
    const childrenDev = (
      <>
        <Skill
          title="Développement orienté Web"
          listCompetences={["React","Typescript", "Express", "Node.js", "MongoDB", "JavaSinglePageApplication"]}
          images={[
            "/galery/webp/skills/front1.webp",
            "/galery/webp/skills/front2.webp",
          ]}
          info="Dev"
        />
        <Skill
          title="Développement orienté Mobile"
          listCompetences={["AndroidStudio", "Kotlin", "Flutter", "Dart"]}
          images={[
            "/galery/webp/skills/mobile1.webp",
            "/galery/webp/skills/mobile2.webp",
          ]}
          info="Dev"
        />
        <Skill
          title="Moteurs de jeu et réalité virtuelle"
          listCompetences={["Unity", "C#", "Blender", "Oculus"]}
          images={[
            "/galery/webp/skills/unity1.webp",
          ]}
          info="Dev"
        />
      </>
    );

    const childrenDesign = (
      <>
        <Skill
          title="Création d'interface utilisateur"
          listCompetences={["Figma", "Excalidraw", "Balsamiq", "Suite Affinity"]}
          images={[
            "/galery/webp/skills/design1.webp",
            "/galery/webp/skills/design2.webp",
          ]}
          info="Design"
        />
        <Skill
          title="Montage et création graphique"
          listCompetences={["Photoshop", "Lightroom", "Illustrator", "Aseprite"]}
          images={[
            "/galery/webp/skills/graphic1.webp",
            "/galery/webp/skills/graphic2.webp",
          ]}
          info="Design"
        />
      </>
    );

    const childrenSoftSkills = (
      <>
        <Skill
          title="Gestion de projet et méthode de travail"
          listCompetences={["Méthode agile", "Kanban, Gannt, Jira", "Organisation et planification", "Priorisation des tâches", "Gestion du temps", "Résolution de problèmes", "Professionnalisme"]}
          info="Soft Skills"
        />
        <Skill
          title="Écrit et oral"
          listCompetences={["Rédaction de documents", "Aisance à l'oral", "Français, Anglais et Allemand"]}
          info="Soft Skills"
        />
        <Skill
          title="Esprit d'équipe"
          listCompetences={["Collaboration", "Communication", "Entraide", "Ecoute"]}
          info="Soft Skills"
        />
        <Skill
          title="Créativité"
          listCompetences={["Imagination", "Innovation", "Curiosité", "Adaptabilité"]}
          info="Soft Skills"
        />
      </>
    );

    return (
      <>
      <Header h1="Compétences &" h2="Réalisations" styleHeader={"/galery/webp/header-bg2.webp"} />
      <div className="pe-5 ps-5" style={{width:'90vw'}}>
        <SkillTitle title="Conception et développement">
          {childrenDev}
        </SkillTitle>
        <SkillTitle title="Design et graphisme">
          {childrenDesign}
        </SkillTitle>
        <SkillTitle title="Soft Skills">
          {childrenSoftSkills}
        </SkillTitle>
      </div>
      </>
    );
  }
}
