import React from 'react';
import '../css/SkillTitle.css';

// Composant SkillTitle qui renvoie une section de compétences liée à un titre dans lequel on peut ajouter des composants enfants
export default function SkillTitle({ title, children }) {
  return (
    <section className='SkillTitle'>
      <h2 className='sticky-title'>{title}</h2>
      {children}
    </section>
  );
}

