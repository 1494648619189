import React from 'react';
import "../css/BigLink.css";

export default function BigLink({ href, icon, text, description }) {
  const style = {
    backgroundImage: `url(${icon})`,
  };

  return (
    <a href={href} className={icon ? 'big-link mb-3' : 'big-link-alt pb-3'}>
      {icon && <span style={style} className="span-image-bigLink"></span>}
      <div className='text-overlay'>
        <h3>{text}</h3>
        {description && <p className='description'>{description}</p>}
      </div>
    </a>
  );
}
