import React from 'react';
import Header from '../components/Header';
import '../css/About.css'; // Assurez-vous de créer ce fichier CSS

export default class About extends React.Component {
  render() {
    return (
      <>
        <Header h1="À propos de moi" h2="Passionné par le développement web et ancré dans l'Histoire" styleHeader={"/galery/webp/header-bg3.webp"} />
        <div className='container mt-5 d-flex align-items-center justify-content-around about-section'>
          <div className='col-12 col-md-8 d-flex flex-column'>
            <span className='h3 mb-3'>Qui suis-je ?</span>  
            <p className='about-text'>
              Je m'appelle Perrin Le Louarn et je suis un étudiant en formation pour devenir ingénieur en informatique. Passionné d'Histoire depuis mon tout jeune âge, j'ai pourtant choisi de me réorienter en BUT MMI après une classe préparatoire à l'école des Chartres. Curieux de tout et de rien, j'aime me tenir au courant des derniers faits dans le monde, mais également des causes qu'ils peuvent avoir, allant parfois jusqu'à m'intéresser à l'origine antique des pays et des coutumes.
            </p>
            <p className='about-text'>
              J'essaie d'associer mes deux passions en réalisant des projets qui me permettent de mettre en avant mes compétences en développement tout en m'amusant à m'inspirer de l'Histoire, de ses personnages et ses évènements.
            </p>
          </div> 
        </div>
      </>
    );
  }
}
